<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.my-button-disabled {
		opacity: 0.2 !important;
	}

	// 修改滚动条的颜色
	div::-webkit-scrollbar-thumb {
		background: #dddddd;
		border-radius: 10px;
	}

	// 版心
	.w {
		width: 1200px;
		margin: 0 auto;
	}

	// 顶部视频播放
	.top {
		width: 100%;
		height: 844px;
		background: url("../../assets/img/live/img1.jpg");
	}

	.course-box {
		position: relative;
		top: 286px;
		display: flex;
		justify-content: space-between;

		border-radius: 0px 6px 6px 6px;
		overflow: hidden;
	}

	.left-course {
		width: 180px;
		line-height: 56px;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		color: #666666;

		div {
			cursor: pointer;
			letter-spacing: 1px;
			margin-bottom: 2.4px;
			height: 56px;
			background: #ffffff;
			border-radius: 6px 0px 0px 6px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.leftCourseActive {
			color: #fff;
			background-color: #e04542;
		}
	}

	.right-course-box {
		box-shadow: 5px 8px 18px 0px rgba(102, 102, 102, 0.2);
		display: flex;
		justify-content: space-between;
		width: 1018px;
		height: 523px;
		background-color: #fff;
	}

	.midden-course {
		width: 700px;
		margin-left: 25px;
	}

	.right-course {
		width: 278px;
		height: 523px;

		// background-color: skyblue;
		.catalog-btn {
			margin-top: 28px;
			width: 254px;
			height: 42px;
			line-height: 42px;
			text-align: center;
			color: #fff;
			font-size: 16px;
			font-weight: bold;
			background: #e04542;
			letter-spacing: 1px;
		}
	}

	.catalog-list {
		margin-top: 25px;
		height: 355px;
		overflow: scroll;
	}

	.catalog-item {
		margin-bottom: 15px;

		.chapter {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 15px;
		}

		.knobble {
			cursor: pointer;
			margin-top: 10px;
			padding-left: 20px;
			font-size: 14px;
			width: 254px;
			height: 31px;
			line-height: 31px;
			box-sizing: border-box;

			&:first-child {
				margin-top: 0;
			}
		}

		.knobbleActive {
			color: #0090f0;
			background: #f4f4f4;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.apply {
		width: 254px;
		height: 42px;
		line-height: 42px;
		background: #e04542;
		box-shadow: 0px 4px 6px 0px rgba(224, 69, 66, 0.43);
		border-radius: 21px;
		text-align: center;
		letter-spacing: 2px;
		font-size: 16px;
		font-weight: bold;
		color: #ffffff;
	}

	.subject-header-menu {
		position: relative;
		padding-top: 30px;
		height: 40px;
		border-bottom: 2px solid #dddddd;
	}

	.video-box {
		margin-top: 10px;
		width: 700px;
		height: 411px;
		background: #f1921f;
	}

	// 修改swiper的样式
	.swiper-container {
		// padding: 0  20px;
		margin: 0 auto;
		width: 630px;
		height: 40px;
		// background-color: #ccc;
	}

	.swiper-slide {
		cursor: pointer;
		padding: 0 14px;
		width: auto;
		text-align: center;
		line-height: 40px;
		margin-right: 5px;
		background: #dddddd;

		&:last-child {
			margin-right: 0;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		margin-top: 0;
		height: 40px;
		top: 30px;
		background-color: red;
	}

	.swiper-button-prev {
		left: 0px;
	}

	.swiper-button-next {
		right: 0px;
	}

	.swiper-button-prev:after,
	.swiper-button-next:after {
		color: #fff;
		font-size: 18px;
	}

	.swiper-button-disabled,
	.swiper-button-disabled {
		opacity: 0.35 !important;
	}

	.swiperActive {
		background-color: #e04542;
		color: #fff;
	}

	.why {
		width: 100%;
		height: 896px;
		background: url("../../assets/img/live/img2.jpg");
	}

	.who {
		width: 100%;
		height: 900px;
		background: url("../../assets/img/live/img3.jpg");
	}

	// 师资团队
	.teacher {
		width: 100%;
		height: 900px;
		background: url("../../assets/img/live/img4.png");
	}

	.teacher-box {
		position: relative;
		margin: 0 auto;
		width: 1208px;
		top: 237px;
		left: 0;
	}

	/deep/ .teacher-box .el-carousel__arrow {
		width: 43px;
		height: 93px;
		border-radius: 0;
		background-color: #e04542;
		font-size: 38px;
		font-weight: bold;
	}

	/deep/ .teacher-box .el-carousel__arrow--left {
		left: 0;
	}

	/deep/ .teacher-box .el-carousel__arrow--right {
		right: 0;
	}

	.introduce {
		position: relative;
		top: 75px;
		margin-left: 114px;
		width: 1022px;
		height: 505px;
		background: #e04542;
		border-radius: 20px;
	}

	.teacher-photos {
		position: absolute;
		margin-top: 29px;
		top: -75px;
		left: -42px;
		width: 388px;
		height: 519px;
		background: #fff;
		box-shadow: -5px -8px 16px 0px rgba(224, 69, 66, 0.47);
		border-radius: 10px;
		overflow: hidden;
	}

	.teacher-photos-img {
		margin-top: 46px;
		width: 388px;
		height: 473px;
	}

	.introduce-con {
		color: #fff;
		margin-left: 404px;
	}

	.teacher-name {
		position: relative;
		padding-top: 55px;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 2px;
		z-index: 1;

		.big-name {

			margin-right: 3px;
			font-size: 40px;
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 155px;
			height: 15px;
			background: #f06664;
			border-radius: 6px;
			z-index: -1;
		}
	}

	.intro {
		margin-top: 30px;
		padding-right: 170px;
		line-height: 36px;
		height: 202px;
		font-size: 16px;
		border-bottom: 1px dotted #ffffff;

		.intro-item {
			display: flex;

			div {
				transform: rotate(145deg);
				margin-top: 12px;
				margin-right: 10px;
				flex-shrink: 0;
				width: 12px;
				height: 12px;
				background: #ffffff;
			}
		}
	}

	.mains {
		font-size: 16px;
		margin-top: 25px;
		height: 140px;
		// background-color: aqua;
	}

	// 在线题库
	.test {
		width: 100%;
		height: 900px;
		background: url("../../assets/img/live/img5.jpg");
	}

	.test-box {
		display: flex;
		position: relative;
		left: 0;
		top: 237px;
	}

	.left-test {
		width: 160px;
	}

	.left-test-menu,
	.left-test-item {
		text-align: center;
		line-height: 44px;
		width: 100%;
		height: 44px;
		font-size: 16px;
		color: #333333;
		background: #ffffff;
	}

	.left-test-item {
		cursor: pointer;
	}

	.leftTestActive {
		position: relative;
		height: 44px;
		background: linear-gradient(90deg, #fde3e2 0%, #ffffff 100%);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 2px;
			height: 44px;
			background: #e04542;
		}
	}

	.right-test {
		width: 1040px;
		height: 533px;
		background: #ffffff;
	}

	.right-test-menu {
		cursor: pointer;
		display: flex;
		height: 44px;
		line-height: 44px;
		font-size: 16px;
		box-sizing: border-box;
		border-bottom: 1px solid #e04542;

		div {
			text-indent: 30px;
			// text-align: center;
			width: 144px;
			background-color: #fff;
		}
	}

	.rightTestMenuActive {
		position: relative;
		color: #fff;
		background: linear-gradient(270deg, #e04542 0%, #ff8155 100%);

		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 0px;
			/*设置宽高为0，所以div的内容为空，从才能形成三角形尖角*/
			height: 0px;
			border-top: 44px solid #fff;
			border-left: 30px solid transparent;
		}
	}

	.test-paper-list {
		height: 489px;
		overflow-y: scroll;
	}

	.test-paper-item {
		display: flex;
		align-items: center;
		margin-left: 30px;
		box-sizing: border-box;
		height: 81.5px;
		border-bottom: 1px solid #f2f2f2;

		&:last-child {
			border-bottom: 0;
		}

		.test-paper-item-title {
			margin-right: 150px;
			flex: 1;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 16px;
			font-weight: 500;
		}

		.test-paper-item-btn {
			cursor: pointer;
			margin-right: 35px;
			text-align: center;
			font-size: 14px;
			color: #ffffff;
			width: 110px;
			height: 36px;
			line-height: 36px;
			background: #e04542;
			box-shadow: 0px 4px 6px 0px rgba(224, 69, 66, 0.43);
			border-radius: 18px;
		}
	}

	// 答疑解惑
	.questions {
		width: 100%;
		height: 900px;
		background: url("../../assets/img/live/img6.png");
	}

	.answer {
		position: relative;
		left: 0;
		top: 237px;
		height: 610px;
		background-color: #fff;
		border-radius: 6px;
		overflow-y: scroll;
	}

	.answer-item {
		padding: 40px 80px 30px 25px;
		border-bottom: 1px solid #f2f2f2;

		&:last-child {
			border-bottom: 0;
		}
	}

	.topic {
		display: flex;
		align-items: center;
	}

	.topic-line {
		width: 4px;
		height: 30px;
		background: #e04542;
	}

	.topic-right {
		margin-left: 10px;
		font-size: 24px;
		font-weight: bold;
	}

	.response {
		margin-top: 10px;
		margin-left: 20px;
		font-size: 16px;
		line-height: 30px;
		color: #666666;
	}

	// .bottom{
	//   width: 100%;
	//   height: 300px;
	//   background-color: #f2f2f2;
	// }
</style>
<template>
	<div>
		<div class="top">
			<div class="w course-box">
				<div class="left-course">
					<div :class="{leftCourseActive:item.id==leftCourseId}" v-for="(item,index) in testSubject"
						:key="index" @click="getLeftCourse(item)">{{item.title}}</div>
				</div>
				<div class="right-course-box">
					<div class="midden-course">
						<div class="subject-header-menu">
							<swiper ref="mySwiper" :options="swiperOptions">
								<swiper-slide :class="{swiperActive:item.id==midCourseID}"
									v-for="(item,index) in midCourse" :key="index" @click.native="getMidCourse(item)">
									{{item.title}}</swiper-slide>
							</swiper>
							<div class="swiper-button-prev" @click="prev"></div>
							<div class="swiper-button-next" @click="next"></div>
						</div>
						<div class="video-box"></div>
					</div>
					<div class="right-course">
						<div class="catalog-btn">目录</div>
						<div class="catalog-list">
							<div class="catalog-item" v-for="(item,index) in knobbleList" :key="index">
								<div class="chapter">{{item.title}}</div>
								<div class="knobble" :class="{knobbleActive:knobbleID==item1.id}"
									v-for="(item1,index1) in item.child" :key="index1" @click="knobbleTap(item1)">
									{{item1.title}}</div>
							</div>
						</div>
						<div class="apply">立即报名</div>
					</div>
				</div>
			</div>
		</div>
		<div class="why"></div>
		<div class="who"></div>
		<!-- 师资团队 -->
		<div class="teacher">
			<div class="teacher-box">
				<el-carousel :interval="5000" arrow="always" height="580px" :autoplay="autoplay"
					indicator-position="none">
					<el-carousel-item v-for="item in 4" :key="item">
						<div class="introduce">
							<div class="teacher-photos">
								<!-- <img class="teacher-photos-img" src="../../assets/img/live/img7.png"> -->
							</div>
							<div class="introduce-con">
								<div class="teacher-name">
									<span class="big-name">武少峰</span>
									<span>老师</span>
								</div>
								<div class="intro" style="list-style:square">
									<div class="intro-item">
										<div></div>
										<p>消防工程师、建造师资深讲师</p>
									</div>
									<div class="intro-item">
										<div></div>
										<p>2010年开始从事国家注册建造师建筑实务培训授课等工作。</p>
									</div>
									<div class="intro-item">
										<div></div>
										<p>曾担任过广州地铁四号线项目技术主管、中国第二大铁路编组站柳州到达场、出发场既有站站改项目副总工程师、山西霍州至临汾段既有站站改项目总工程师、济祁高速永城段
										</p>
									</div>
								</div>
								<div class="mains">主讲课程 :</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
		</div>
		<!-- 在线题库 -->
		<div class="test">
			<div class="w test-box">
				<div class="left-test">
					<div class="left-test-menu">科目</div>
					<div class="left-test-item left-test-active" :class="{leftTestActive:item.id==testSubjectID}"
						v-for="(item,index) in testSubject" :key="index" @click="testSubjectTap(item)">{{item.title}}
					</div>
				</div>
				<div class="right-test">
					<div class="right-test-menu">
						<div :class="{rightTestMenuActive:item.id==testMenuId}" v-for="(item,index) in testMenu"
							:key="index" @click="testMenuTap(item)">{{item.title}}</div>
					</div>
					<!-- 试卷列表 -->
					<div class="test-paper-list">
						<div class="test-paper-item" v-for="(item,index) in 6" :key="index">
							<div class="test-paper-item-title">2020消防工程师《综合能力》模拟试卷</div>
							<div class="test-paper-item-btn">开始做题</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 答疑解惑 -->
		<div class="questions">
			<div class="w answer">
				<div class="answer-item" v-for="(item,index) in 4" :key="index">
					<div class="topic">
						<div class="topic-line"></div>
						<div class="topic-right">何时寄送书籍资料？</div>
					</div>
					<div class="response">若您报名的课程包含赠送教材de配套服务，一般情况下，支付成功后将在48小时内安排邮寄。</div>
				</div>
			</div>
		</div>
		<!-- <div class="bottom"></div> -->
	</div>
</template>
<script>
	export default {
		components: {},
		data() {
			return {
				swiperOptions: {
					slidesPerView: "auto",
					// spaceBetween: 5,
					pagination: {
						el: ".swiper-pagination",
					},
					// navigation: {
					//   nextEl: ".swiper-button-next",
					//   prevEl: ".swiper-button-prev",
					//   disabledClass: 'my-button-disabled',
					// },
					// Some Swiper option/callback...
				},
				autoplay: false,
				leftCourseId: 1,
				testSubjectID: 1,
				testMenuId: 1,
				knobbleID: 11,
				midCourseID: 1,
				midCourse: [{
						id: 1,
						title: "其他建筑防火",
					},
					{
						id: 2,
						title: "其他建筑防火",
					},
					{
						id: 3,
						title: "其他建筑防火",
					},
					{
						id: 4,
						title: "其他建筑防火标题标题标题",
					},
					{
						id: 5,
						title: "其他建筑防火标题标题标题",
					},
					{
						id: 6,
						title: "其他建筑防火标题标题标题",
					},
				],
				knobbleList: [{
						id: 1,
						title: "第一章 标题标题标题标题标题",
						child: [{
								id: 11,
								title: "第一节",
								status: 1,
							},
							{
								id: 12,
								title: "第二节",
								status: 1,
							},
							{
								id: 13,
								title: "第三节",
								status: 1,
							},
							{
								id: 14,
								title: "第四节",
								status: 1,
							},
						],
					},
					{
						id: 2,
						title: "第二章 标题标题标题标题标题",
						child: [{
								id: 21,
								title: "第一节",
								status: 1,
							},
							{
								id: 22,
								title: "第二节",
								status: 1,
							},
							{
								id: 23,
								title: "第三节",
								status: 1,
							},
							{
								id: 24,
								title: "第四节",
								status: 1,
							},
						],
					},
					{
						id: 3,
						title: "第三章 标题标题标题标题标题",
						child: [{
								id: 31,
								title: "第一节",
								status: 1,
							},
							{
								id: 32,
								title: "第二节",
								status: 1,
							},
							{
								id: 33,
								title: "第三节",
								status: 1,
							},
							{
								id: 34,
								title: "第四节",
								status: 1,
							},
						],
					},
				],
				testMenu: [{
						id: 1,
						title: "模拟练习",
					},
					{
						id: 2,
						title: "历年真题",
					},
					{
						id: 3,
						title: "错题记录",
					},
				],
				testSubject: [{
						id: 1,
						title: "综合能力",
					},
					{
						id: 2,
						title: "技术实务",
					},
					{
						id: 3,
						title: "案例分析",
					},
					// {
					//   id: 4,
					//   title: "综合能力",
					// },
					// {
					//   id: 5,
					//   title: "技术实务",
					// },
					// {
					//   id: 6,
					//   title: "案例分析",
					// },
					// {
					//   id: 7,
					//   title: "综合能力",
					// },
					// {
					//   id: 8,
					//   title: "技术实务",
					// },
					// {
					//   id: 9,
					//   title: "案例分析",
					// },
				],
			};
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper;
			},
		},
		created() {},
		mounted() {
			this.swiper.slideTo(0, 1000, false);
		},
		methods: {
			prev() {
				this.swiper.slidePrev();
			},
			next() {
				this.swiper.slideNext();
			},
			testSubjectTap(item) {
				// console.log(item);
				this.testSubjectID = item.id;

			},
			testMenuTap(item) {
				// console.log(item);
				this.testMenuId = item.id;
			},
			getLeftCourse(item) {
				// console.log(item);
				this.leftCourseId = item.id;
			},
			knobbleTap(item1) {
				// console.log(item1);
				this.knobbleID = item1.id;
			},
			getMidCourse(item) {
				// console.log(item);
				this.midCourseID = item.id;
			},
		},
	};
</script>
